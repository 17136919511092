import "./Policy.css";

function Policy(): JSX.Element {
    return (
        <div className="Policy">
            <h1>תקנון</h1>
            <h3>מדיניות שילוח</h3>
            <section>
                משלוח עד בית הלקוח
                על המזמין להיות תושב ישראל בעל ת.ז ישראלית ומעל גיל 18 בעל כרטיס אשראי בתוקף.
                השליח יוצר קשר לפני מועד האספקה ומתאם מועד נוח.<br />
                משלוח אקספרס - משלוח עד 3 ימי עסקים , עלות המשלוח 50 ש"ח . <br />
                משלוח רגיל - המשלוח מגיע עד 8 ימי עסקים מיום ביצוע הרכישה . עלות משלוח בשירות שליח עד הבית 35 ₪. בקנייה מעל 200 ₪ המשלוח בחינם .<br/>
                (בתקופות מבצעים/חגים/ומועדים מיוחדים משלוחים עלולים להתעכב ל- 14 ימי עסקים)
                משלוחים המיועדים להפצה באזורים חריגים (לרבות יישובי רמת הגולן, גבול הצפון, יישובי המגזר הערבי, יישובי בקעת הירדן, ישובים ממעבר לקו הירוק, יישובי עוטף עזה, אילת, ים המלח ויישובי הערבה) בהתאם לנהלי חברת השליחויות יתבצעו תוך 14 ימי עסקים. לא יתבצעו משלוחים לישובים או כפרים ערביים וישובים מעבר לקו הירוק בעלי סיכון ביטחוני.
                <p>איסוף עצמי</p>
                ניתן לבצע איסוף עצמי ממשרדינו ברח' חיים ויצמן רמלה. יש לתאם הגעה מראש בימים א'-ה' בין השעות 08:30-16:00 ימי ו' לסירוגין.
                טלפון לתיאום <a target="blank" href="tel:0503713852">050-3713852</a>
                <p>החלפות</p>
                החלפות ניתן לעשות תוך שבועיים מיום הרכישה במשרדינו בהצגת חשבונית רכישה.ההחלפה תתבצע אך ורק כאשר המוצר סגור ולא היה בו שימוש . שווי ההחלפה יהיה לפי הסכום ששולם בפועל על המוצרים.
                <p>ביטול עסקה, החזרות וזיכוי כספי</p>
                יש לפנות אלינו בכתב למייל  <a href="mailto:donaroma.sr@gmail.com">donaroma.sr@gmail.com</a>
                ולציין את מספר ההזמנה וסוג הפריט/ים שרוצים להחזיר.
                לקבלת זיכוי כספי יש להחזיר את הפריט/ים בתוך 14 ימים מיום קבלת ההזמנה. ניתן להחזיר ישירות ועצמאית למשרדינו ברח' ויצמן רמלה בתיאום טלפוני מראש
                ניתן להחזיר גם באמצעות דואר רשום. יש לשלוח אימייל עם מספר המעקב של הדואר הרשום לכתובת <a href="mailto:donaroma.sr@gmail.com">donaroma.sr@gmail.com</a>
                . לא תתקבל החזרה שנשלחה בדואר רגיל ו/או ללא מספר מעקב. לכבוד דון ארומה , שד' חיים ויצמן רמלה

            </section>
            <h3>פרטיות</h3>
            <section>
                <ul>
                    <li> האתר מתחייב לא להעביר את כל פרטי הלקוח לצד ג'.</li>
                    <li> המידע אשר הנך מוסר בעת ההזמנה, ובין היתר את פרטייך האישים, נשמור במאגר המידע שלנו באופן חלקי. חשוב לציין שפרטי כרטיס האשראי אינם נשמרים במערכותינו ונמחקים מיד באופן אוטומטי מיד לאחר החיוב.</li>
                    <li>אנו נוקטים באמצעי זהירות מקובלים על מנת לשמור ככל האפשר על סודיות המידע. במקרים שאינם בשליטתינו ו/או הנובעים מכוח עליון, לא נהיה אחראיים לכל נזק מכל סוג שהוא, ישר או עקיף, שייגרם ללקוח, אם מידע זה יאבד ו/או יעשה בו שימוש לא מורשה.</li>
                    <li>מכשיר תקשורת אחר (כדוגמת טלפון סלולארי, טבלטים למיניהם וכו’) כמו כן הם חלים על השימוש באתר, בין באמצעות רשת האינטרנט ובין באמצעות כל רשת או אמצעי תקשורת אחר.
                        אין באמור בתקנון זה כדי לגרוע מהוראות חוק הגנת הצרכן, התשמ”א-1981
                    </li>
                    <li> (“חוק הגנת הצרכן”) והתקנות אשר הותקנו מכוחו, ככל שהן חלות על האתר
                        (להלן: “ההוראות”), למעט במקרים בהם ניתן להתנות על הוראות כאמור והתניה כאמור בוצעה במסגרת האתר אם במפורש ואם במשתמע.
                    </li>
                    <li>בהרשמה לאתר ו/או לאחר ביצוע הזמנה באתר הינך מאשר קבלת מידע וחומרים שיווקיים מאת דון ארומה.</li>
                </ul>
            </section>
            <h3>קריאת שירות</h3>
            <section>
                <ul>
                    <li> קריאת שירות תתבצע תוך שני ימי עסקים.</li>
                    <li> קריאות שירות מתקבלות בין השעות 8:30-12:00 קריאה שתתקבל לאחר מכן תיחשב ליום עסקים הבא.</li>

                </ul>
            </section>
            <h3>הנחיות ואחריות בית העסק/אתר האינטרנט</h3>
            <section>
                <ul>
                    <li>אין להסיר את הטיקטים. לא ניתן להחזיר פריט שהשתמשת בו.</li>
                    <li>כל שימוש במוצר או במידע שנרכש באתר באחריות המשתמש בלבד. <br />
                        איננו אחראיים לכל נזק ישיר/עקיף שיגרם כתוצאה מהשימוש במוצר או בשירות.</li>
                    <li> כל מידע או תוכן שימסר באתר אינו מהווה יעוץ מקצועי ובגדר המלצה בלבד.</li>
                    <li> לא ניתן לקבל החזר כספי עבור המשלוח  (במידה ושולם משלוח).</li>
                    <li>לא ניתן להחזיר מכשיר או פריט שהיה בשימוש !</li>
                    <li>אין אחריות על מכשיר מפיץ ריח אשר היה בו שימוש של שמן שלא נקנה מדון ארומה</li>
                    <li>אחריות תינתן על תקלה טכנית בלבד למשך שנה מתאריך הקנייה.</li>
                    <li>לא תנתן אחריות על שבר או נזק שיגרם ע"י הלקוח.</li>
                </ul>
            </section>
        </div>
    );
}

export default Policy;
