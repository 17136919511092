import "./AboutUs.css";

function AboutUs(): JSX.Element {
    return (
        <div className="AboutUs">
            <h1>אודות Don Aroma</h1>
            <section>
                לאחר כשלוש שנים של עיסוק בתחום הריח והבישום,
                הרגשנו שהגיע לכם- קהל לקוחותינו לקבל את
                המקסימום בעסקי מפיצי הריח והניחוחות לעסק או
                לבית.
                המון בקשות ממגוון רחב של אנשים ומכרים להרים
                את הכפפה ולהתחיל להקים את העסק שלנו, הילד
                שלנו שנקרא "דון ארומה"
                אנחנו שמחים ונרגשים לבשר לכם שהנה, הגיעה
                המהפכה ואנחנו פה בשבילכם לכל קריאה או שאלה.
                מקווים שנלך יד ביד בדרך ארוכה אל עבר המסע .
                תודה לכם ותודה עליכם, צוות "דון ארומה"
            </section>
        </div>
    );
}

export default AboutUs;
